import tabletImg from '../images/tablet-doc-provident.png';

import DemoBase from './DemoBase';

function ProvidentDemo() {

  const items = [{
    key: 'provident',
    description: 'Kölcsönszerződés minta',
    image: tabletImg
  }];

  return (
    <DemoBase items={items} />
  );
}

export default ProvidentDemo;
