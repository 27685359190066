import './App.scss';
import Template from './pages/Template';
import Demo from './pages/Demo';
import ProvidentDemo from './pages/ProvidentDemo';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
}

TagManager.initialize(tagManagerArgs);

function App() {

  const DemoSite = () => {
    return (
      <main>
        <BrowserRouter>
          <Switch>
            <Route path="/provident" component={ProvidentDemo} />
            <Route path="" component={Demo} />
          </Switch>
        </BrowserRouter>
      </main>
    );
  }

  const Site = () => {
    return (
      <main>
        <BrowserRouter>
          <Switch>
            <Route path="" component={Template} />
          </Switch>
        </BrowserRouter>
      </main>
    );
  }

  switch(process.env.REACT_APP_SITE) {
    case 'demo.signowise.hu':
      return DemoSite();
    default:
      return Site();
  }
}

export default App;
