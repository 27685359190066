import tabletImgMedicalRecord from '../images/tablet-doc-medical-record.png';
import tabletImgRent from '../images/tablet-doc-rent.png';

import DemoBase from './DemoBase';

function Demo() {

  const items = [{
    key: 'medical_record',
    description: 'Ambuláns vizsgálati lap',
    image: tabletImgMedicalRecord
  }, {
    key: 'rent',
    description: 'Gépbérleti szerződés minta',
    image: tabletImgRent
  }];

  return (
    <DemoBase items={items} />
  );
}

export default Demo;
