import { useState } from 'react';
import '../App.scss';
import DemoHeader from '../sections/header/DemoHeader';
import Footer from '../sections/footer/Footer';

import demoService from '../services/demo';
import { openPopup, secureFetch } from '../libs/utils'

function DemoBase(props) {
  const { items } = props;
  const [errorMessage, setErrorMessage] = useState('');

  const startSampleDocument = async (sampleDocumentPath) => {
    const url = await secureFetch(
      () => demoService.startSampleDocument(sampleDocumentPath),
      (response) => {
        const url = `/sign/index.html?workflowId=${response.workflowId}` +
          `&token=${response.token}`;
        return url;
      },
      setErrorMessage,
      'Nem sikerült betölteni a dokumentumot'
    );
    return url;
  }

  return (
    <>
      <DemoHeader />
      <section className="demo">
        <div className="container">
          <div className="row mb-5 pb-5 justify-content-center">
            <div className="col-lg-12 demo__title">
              <h2>SignoWise biometrikus e-aláírás mintadokumentum demo</h2>
            </div>
            {errorMessage && (
                <div className="col-lg-12 demo__error">
                  {errorMessage}
                </div>
              )}
            {items.map(item => (
              <div className="col-lg-6 demo__item">
                <button id={item.key} onClick={() =>
                  openPopup(startSampleDocument(item.key))}>
                  <p>{item.description}</p>
                    <img
                      src={item.image}
                      alt="advantageImg"
                      className="advantage-img"
                    />
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default DemoBase;
